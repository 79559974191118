define('coffee-journal/components/log-out', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'a',
		classNames: ['account__logout'],

		click: function click() {
			this.get('logout')(this);
		}
	});
});