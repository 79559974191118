define('coffee-journal/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		full_name: _emberData.default.attr(),
		first_name: _emberData.default.attr(),
		last_name: _emberData.default.attr(),
		email: _emberData.default.attr(),
		subscription: _emberData.default.attr('string', { defaultValue: '0' }),
		brews: _emberData.default.hasMany('brew'),
		devices: _emberData.default.hasMany('device'),
		grinders: _emberData.default.hasMany('grinder'),
		locale: _emberData.default.attr('string', { defaultValue: 'en-us' }),
		weight_unit: _emberData.default.attr('string', { defaultValue: 'grams' }),
		fluid_unit: _emberData.default.attr('string', { defaultValue: 'liters' }),
		temperature_unit: _emberData.default.attr('string', { defaultValue: 'celcius' }),
		currency: _emberData.default.attr('string', { defaultValue: 'euros' }),
		date_format: _emberData.default.attr('string', { defaultValue: 'DD-MM-YYYY' })
	});
});