define('coffee-journal/instance-initializers/ember-intl', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.instanceInitializer = instanceInitializer;
  function instanceInitializer(instance) {
    Ember.deprecate('[ember-intl] instance initializer is deprecated, no longer necessary to call in testing.', false, {
      id: 'ember-intl-instance-initalizer'
    });
  } /**
     * Copyright 2015, Yahoo! Inc.
     * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
     */

  exports.default = {
    name: 'ember-intl',
    initialize: function initialize() {}
  };
});