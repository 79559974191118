define('coffee-journal/components/sign-up', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['sign-up is-hidden'],
		reCaptchaResponse: false,

		actions: {
			cancelSignUp: function cancelSignUp() {
				var elements = document.querySelectorAll('.log-in, .sign-up');
				for (var i = 0; i < elements.length; i++) {
					elements[i].classList.toggle('is-hidden');
				}
				document.querySelector('.login__box').classList.toggle('login__box--signup');
			},
			signUp: function signUp() {

				if (this.get('reCaptchaResponse')) {
					this.get('signUp')(this);
				} else {
					var messageBox = this.element.querySelector('.form__error--general');
					messageBox.classList.add('has-error', 'shake');
					messageBox.textContent = 'Captcha is required';
				}
			},
			onCaptchaResolved: function onCaptchaResolved() {
				this.set('reCaptchaResponse', true);
			}
		}
	});
});