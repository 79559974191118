define('coffee-journal/torii-adapters/application', ['exports', 'emberfire/torii-adapters/firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    firebase: Ember.inject.service(),
    store: Ember.inject.service(),

    open: function open(authData) {
      return this._findOrCreateUser(authData).then(function (user) {
        return {
          currentUser: user
        };
      });
    },


    // close() {
    //   this.get('store').unloadAll();
    // 	debugger;
    //   this.get('firebase').auth().signOut();
    //   return new Ember.RSVP.Promise(resolve => {
    //
    //     resolve({
    //       currentUser: null
    //     });
    //   });
    // },

    _findOrCreateUser: function _findOrCreateUser(authData) {
      var _this = this;

      var store = this.get('store');

      return store.find('user', authData.uid).catch(function () {
        var newUser = store.createRecord('user', _this.extractUserProperties(authData));

        return newUser.save();
      });
    },
    extractUserProperties: function extractUserProperties(authData) {
      return {
        id: authData.uid,
        displayName: authData.displayName || authData.username || 'Unknown',
        email: authData.email || null,
        photoUrl: authData.photoUrl || null
      };
    }
  });
});