define('coffee-journal/components/brew-form', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		routing: Ember.inject.service('-routing'),
		blend: false,
		store: Ember.inject.service(), // Service name is not necessary because it's already named 'store'
		historyVisible: false,
		washOptions: [{
			value: 'unwashed',
			title: 'Unwashed'
		}, {
			value: 'washed',
			title: 'Washed'
		}, {
			value: 'semi-washed',
			title: 'Semi-Washed/Half-washed'
		}, {
			value: 'honey_dried',
			title: 'Honey dried'
		}],
		roastOptions: [{
			value: 'light_city',
			title: 'Light City'
		}, {
			value: 'half_city',
			title: 'Half City'
		}, {
			value: 'cinnamon',
			title: 'Cinnamon'
		}, {
			value: 'city',
			title: 'City'
		}, {
			value: 'american',
			title: 'American'
		}, {
			value: 'breakfast',
			title: 'Breakfast'
		}, {
			value: 'full_city',
			title: 'Full City'
		}, {
			value: 'high',
			title: 'High'
		}, {
			value: 'continental',
			title: 'Continental'
		}, {
			value: 'new_orleans',
			title: 'New Orleans'
		}, {
			value: 'european',
			title: 'European'
		}, {
			value: 'espresso',
			title: 'Espresso'
		}, {
			value: 'viennese',
			title: 'Viennese'
		}, {
			value: 'italian',
			title: 'Italian'
		}, {
			value: 'french',
			title: 'French'
		}],
		methodOptions: [{
			value: 'semi-automatic',
			title: 'Semi-automatic'
		}, {
			value: 'lever',
			title: 'Lever'
		}, {
			value: 'v60',
			title: 'V60'
		}, {
			value: 'kalita_wave',
			title: 'Kalita Wave'
		}, {
			value: 'chemex',
			title: 'Chemex'
		}, {
			value: 'stovetop',
			title: 'Stovetop'
		}, {
			value: 'french-press',
			title: 'French-press'
		}, {
			value: 'turkish',
			title: 'Turkish'
		}, {
			value: 'cupping',
			title: 'Cupping'
		}],

		varietalOptions: [{
			value: 'arusha',
			title: 'Arusha'
		}, {
			value: 'bergendal-sidikalang',
			title: 'Bergendal, Sidikalang'
		}, {
			value: 'blue-mountain',
			title: 'Blue Mountain'
		}, {
			value: 'bourbon',
			title: 'Bourbon'
		}, {
			value: 'catuai',
			title: 'Catuai'
		}, {
			value: 'catimor',
			title: 'Catimor'
		}, {
			value: 'caturra',
			title: 'Caturra'
		}, {
			value: 'charrieriana',
			title: 'Charrieriana'
		}, {
			value: 'colombian',
			title: 'Colombian'
		}, {
			value: 'ethiopian-harar',
			title: 'Ethiopian Harar'
		}, {
			value: 'ethiopian-heirloom',
			title: 'Ethiopian Heirloom'
		}, {
			value: 'ethiopian-sidamo',
			title: 'Ethiopian Sidamo'
		}, {
			value: 'ethiopian-yirgacheffe',
			title: 'Ethiopian Yirgacheffe'
		}, {
			value: 'french-mission',
			title: 'French Mission'
		}, {
			value: 'gesha',
			title: 'Geisha/Gesha'
		}, {
			value: 'guadeloupe-bonifieur',
			title: 'Guadeloupe Bonifieur'
		}, {
			value: 'hawaiian-kona',
			title: 'Hawaiian Kona'
		}, {
			value: 'java',
			title: 'Java'
		}, {
			value: 'k7',
			title: 'K7'
		}, {
			value: 'maragogipe',
			title: 'Maragogipe'
		}, {
			value: 'maragaturra',
			title: 'Maragaturra'
		}, {
			value: 'mayaguez',
			title: 'Mayagüez'
		}, {
			value: 'mocha',
			title: 'Mocha'
		}, {
			value: 'mundo novo',
			title: 'Mundo Novo'
		}, {
			value: 'orange-yellow-bourbon',
			title: 'Orange, Yellow Bourbon'
		}, {
			value: 'pacamara',
			title: 'Pacamara'
		}, {
			value: 'pacas',
			title: 'Pacas'
		}, {
			value: 'pache-colis',
			title: 'Pache Colis'
		}, {
			value: 'pache-comum',
			title: 'Pache Comum'
		}, {
			value: 'ruiru-11',
			title: 'Ruiru 11'
		}, {
			value: 's795',
			title: 'S795'
		}, {
			value: 'santos',
			title: 'Santos'
		}, {
			value: 'sarchimor',
			title: 'Sarchimor'
		}, {
			value: 'selection-9',
			title: 'Selection 9'
		}, {
			value: 'sl28',
			title: 'SL28'
		}, {
			value: 'sl34',
			title: 'SL34'
		}, {
			value: 'sulawesi-toraja-kalossi',
			title: 'Sulawesi Toraja Kalossi'
		}, {
			value: 'sumatra-mandheling',
			title: 'Sumatra Mandheling'
		}, {
			value: 'sumatra-lintong',
			title: 'Sumatra Lintong'
		}, {
			value: 'timor-arabusta',
			title: 'Timor, Arabusta'
		}, {
			value: 'typica',
			title: 'Typica'
		}, {
			value: 'uganda',
			title: 'Uganda'
		}, {
			value: 'brutte',
			title: 'Brutte'
		}],

		didInsertElement: function didInsertElement() {

			if (this.get('routing').router.currentPath == 'brews.new') {
				this.$('.input-brew-name').focus();
			}
		},
		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.element.classList.add('fade-in');

			var brew = this.brew;
			if (brew.get('name_blend') || brew.get('origin_blend') || brew.get('producer_blend') || brew.get('roast_blend') || brew.get('roaster_blend') || brew.get('wash_blend')) {
				this.set('blend', true);
			}

			if (brew.get('parent.name_blend') || brew.get('parent.origin_blend') || brew.get('parent.producer_blend') || brew.get('parent.roast_blend') || brew.get('parent.roaster_blend') || brew.get('parent.wash_blend')) {
				this.set('blend', true);
			}
		},


		actions: {
			toggleNotes: function toggleNotes() {
				document.querySelector('.notes').classList.toggle('is-visible');
			},
			setVarietal: function setVarietal(selected) {
				this.brew.set('varietal', selected);
			},
			setWash: function setWash(selected) {
				this.brew.set('wash', selected);
			},
			setRoast: function setRoast(selected) {
				this.brew.set('roast', selected);
			},
			setBrewDevice: function setBrewDevice(selected) {
				this.brew.set('device', selected);
			},
			setGrinder: function setGrinder(selected) {
				this.brew.set('grinder', selected);
			},
			addVarietal: function addVarietal() {
				var varietalArray = this.brew.get('varietal');

				this.brew.set('varietal', varietalArray);
			},
			removeVarietal: function removeVarietal(value) {
				var varietalArray = this.brew.get('varietal');

				varietalArray = varietalArray.filter(function (item) {
					return item.value !== value;
				});

				this.brew.set('varietal', varietalArray);
			},
			selectDevice: function selectDevice(value) {
				var store = this.get('store'),
				    currentDevice = store.peekRecord('device', value);

				currentDevice.save();
			},


			// TODO: Add removeDevice function

			selectGrinder: function selectGrinder(value) {
				var store = this.get('store'),
				    currentGrinder = store.peekRecord('grinder', value);

				currentGrinder.save();
			},


			// TODO: Add removeGrinder function

			limitCharacters: function limitCharacters() {
				var max_chars = 1,
				    input = event.target;

				if (input.value.length > max_chars) {
					input.value = input.value.substr(0, max_chars);
				}

				if (input.value >= 6) {
					input.value = 5;
				}
			},
			handleTrixEvent: function handleTrixEvent(event) {
				var document = event.target.editor.getDocument(),
				    raw = Trix.serializeToContentType(document, 'text/html');

				this.brew.set('notes', raw);
			},
			handleTrixInitializeEvent: function handleTrixInitializeEvent(event) {
				var notes = this.brew.get('notes');

				if (notes) {
					event.target.editor.insertHTML(notes);
				}
			},
			updatePoint: function updatePoint(value) {
				console.log('updatePoint(' + value + ');');
				// brewChart.points[0].value = value;
				// brewChart.update();
			},
			toggleBlend: function toggleBlend() {
				this.toggleProperty('blend');
				if (!this.get('blend')) {
					this.brew.set('name_blend', '');
					this.brew.set('origin_blend', '');
					this.brew.set('producer_blend', '');
					this.brew.set('roast_blend', '');
					this.brew.set('roaster_blend', '');
					this.brew.set('wash_blend', '');
				}
			},
			toggleHistory: function toggleHistory() {
				this.toggleProperty('historyVisible');
			},
			updateRating: function updateRating(rating) {
				this.brew.set('rating', rating);
			}
		}

	});
});