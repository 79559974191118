define('coffee-journal/components/log-in', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['log-in'],

		willRender: function willRender() {
			Ember.$('body').addClass('page-login');
		},

		willClearRender: function willClearRender() {
			Ember.$('body').removeClass('page-login');
		},

		actions: {
			login: function login() {
				this.get('login')(this);
			},
			switchLoginType: function switchLoginType() {
				var elements = document.querySelectorAll('.log-in, .sign-up');
				for (var i = 0; i < elements.length; i++) {
					elements[i].classList.toggle('is-hidden');
				}
				document.querySelector('.login__box').classList.toggle('login__box--signup');
			}
		}

	});
});