define('coffee-journal/models/brew', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		uid: _emberData.default.attr('string'),
		user: _emberData.default.belongsTo('user'),
		favorite: _emberData.default.attr('boolean', { defaultValue: false }),
		name: _emberData.default.attr('string'),
		rating: _emberData.default.attr('number', { defaultValue: 2.5 }),
		origin: _emberData.default.attr('string'),
		roaster: _emberData.default.attr('string'),
		producer: _emberData.default.attr('string'),
		varietal: _emberData.default.attr({ defaultValue: function defaultValue() {
				return [];
			} }),
		wash: _emberData.default.attr('string'),
		roast: _emberData.default.attr('string'),
		roast_date: _emberData.default.attr('string'),
		package_date: _emberData.default.attr('string'),
		brew_date: _emberData.default.attr('string', { defaultValue: (0, _moment.default)().format("YYYY-MM-DD") }),
		beverage: _emberData.default.attr('string'),
		basket_weight: _emberData.default.attr('number'),
		fluid_weight: _emberData.default.attr('number'),
		temperature_in: _emberData.default.attr('number'),
		temperature_out: _emberData.default.attr('number'),
		time: _emberData.default.attr('string'),
		device: _emberData.default.belongsTo('device'),
		grinder: _emberData.default.belongsTo('grinder'),
		url: _emberData.default.attr('string'),
		notes: _emberData.default.attr('string'),
		wash_blend: _emberData.default.attr('string'),
		roast_blend: _emberData.default.attr('string'),
		origin_blend: _emberData.default.attr('string'),
		name_blend: _emberData.default.attr('string'),
		roaster_blend: _emberData.default.attr('string'),
		producer_blend: _emberData.default.attr('string'),
		taste_sweet: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_sour: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_floral: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_spicy: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_salty: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_berry: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_citrus: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_stone: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_chocolate: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_caramel: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_smoky: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_bitter: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_savory: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_body: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_clean: _emberData.default.attr('number', { defaultValue: 5 }),
		taste_linger: _emberData.default.attr('number', { defaultValue: 5 }),
		children: _emberData.default.hasMany('brew', { inverse: 'parent' }),
		parent: _emberData.default.belongsTo('brew', { inverse: 'children' })
	});
});