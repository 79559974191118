define('coffee-journal/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		firebaseApp: Ember.inject.service(),
		errorMessage: false,

		actions: {
			signUp: function signUp(componentData) {
				var _this2 = this;

				var auth = this.get('firebaseApp').auth();
				var firstName = componentData.childViews[1].get('firstName'),
				    lastName = componentData.childViews[1].get('lastName'),
				    email = componentData.childViews[1].get('email'),
				    password = componentData.childViews[1].get('password'),
				    password_confirm = componentData.childViews[1].get('password_confirm');

				document.querySelector('.form__error--general').classList.remove('has-error', 'shake');

				if (password === password_confirm) {

					if (email && password) {

						auth.createUserWithEmailAndPassword(email, password).catch(function (error) {
							var messageBox = document.querySelector('.form__error--general');
							messageBox.classList.add('has-error', 'shake');

							switch (error.code) {
								case 'auth/weak-password':
									messageBox.textContent = 'Weak password, please enter a minimum of 6 characters';
									break;
								case 'auth/email-already-in-use':
									messageBox.textContent = 'Email address is already in use';
									break;
								case 'auth/invalid-email':
									messageBox.textContent = 'Invalid email address';
									break;
								default:
									messageBox.textContent = error.message;
							}
						}).then(function (userResponse) {

							if (userResponse) {

								var user = _this2.store.createRecord('user', {
									id: userResponse.uid,
									full_name: firstName + ' ' + lastName,
									first_name: firstName,
									last_name: lastName,
									email: email
								});

								user.save().then(function () {

									auth.onAuthStateChanged(function (user) {
										user.sendEmailVerification();
									});

									return _this2.get('session').fetch().catch(function (error) {
										console.log(error);
									}).then(function () {
										// this.transitionToRoute('dashboard');
										_this2.transitionToRoute('brews');
									});
								});
							} // if
						});
					} else {
						var messageBox = document.querySelector('.form__error--general');
						messageBox.classList.add('has-error', 'shake');
						messageBox.textContent = 'Please enter an email address and password';
					} // end if else
				} else {
					var messageBox = document.querySelector('.form__error--general');
					messageBox.classList.add('has-error', 'shake');
					messageBox.textContent = "Passwords don't match, please try again.";
				}
			},
			// signUp

			login: function login(componentData) {
				var email = componentData.childViews[0].get('email'),
				    password = componentData.childViews[0].get('password'),
				    _this = this;

				this.get('session').open('firebase', {
					provider: 'password',
					email: email,
					password: password

				}).catch(function (error) {

					if (error.message) {
						_this.set('errorMessage', error.message);
					} else {
						_this.set('errorMessage', 'Authentication error. Please check if you entered the correct email address and/or password.');
					}
				}).then(function () {
					// _this.transitionToRoute('dashboard');
					_this.transitionToRoute('brews');
				});
			},
			logout: function logout() {
				this.store.unloadAll('brew');
				this.store.unloadAll('user');
				this.get('session').close().then(function () {
					this.transitionToRoute('index');
				}.bind(this));
			},
			goToNewBrew: function goToNewBrew() {
				this.transitionToRoute('brews.new');
			}
		} // actions

	});
});