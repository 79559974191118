define('coffee-journal/routes/brews/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		activate: function activate() {
			Ember.$('body').removeClass('page-login');
		},

		model: function model() {
			var currentUserId = this.get('session.currentUser.id');

			return Ember.RSVP.hash({
				user: this.get('session.currentUser'),
				userBrews: this.store.query('brew', { orderBy: 'uid', equalTo: currentUserId })
			});
		},
		setupController: function setupController(controller, model) {
			controller.set('userBrews', model.userBrews);
		}
	});
});