define('coffee-journal/routes/user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		intl: Ember.inject.service(),
		firebaseApp: Ember.inject.service(),

		model: function model() {
			var currentUserId = this.get('session.currentUser.id');

			return Ember.RSVP.hash({
				user: this.get('session.currentUser'),
				userBrews: this.store.query('brew', { orderBy: 'uid', equalTo: currentUserId }),
				devices: this.store.query('device', { orderBy: 'user', equalTo: currentUserId }),
				grinders: this.store.query('grinder', { orderBy: 'user', equalTo: currentUserId })
			});
		},
		setupController: function setupController(controller, model) {
			controller.set('user', model.user);
			controller.set('userBrews', model.userBrews);
			controller.set('devices', model.devices);
			controller.set('grinders', model.grinders);

			this.get('firebaseApp').auth().onAuthStateChanged(function (user) {
				user.reload();

				if (user) {

					if (user.emailVerified) {
						controller.set('isNotVerified', false);
					}
				}
			});
		},


		actions: {
			saveChanges: function saveChanges(currentUser) {
				var _this = this;
				var user = this.get('firebaseApp').auth().currentUser;

				if (_this.get('connectionStatus.online')) {

					if (currentUser.get('email') !== user.email) {
						// https://firebase.google.com/docs/reference/js/firebase.User.html#updateEmail
						user.updateEmail(currentUser.get('email')).then(function () {
							console.log('email has changed to: ' + currentUser.get('email'));
							// https://firebase.google.com/docs/reference/js/firebase.User.html#reload
							user.reload();
						}).catch(function (error) {
							console.log(error);

							if (error.code === 'auth/requires-recent-login') {
								_this.controller.set('reauthenticationIsRequired', true);
							}
						}.bind(this));
					} else {

						// https://firebase.google.com/docs/reference/js/firebase.User.html#updateProfile
						user.updateProfile({
							displayName: _this.context.user.get('first_name') + ' ' + _this.context.user.get('last_name')
						}).then(function () {
							user.reload();
						}, function (error) {
							console.log(error);
							_this.notify.error(error.code);
						});

						currentUser.save().then(function () {
							_this.notify.success('Successfully saved changes!');
							_this.get('intl').setLocale(_this.context.user.get('locale'));
						}).catch(function (error) {
							console.log(error);
							_this.notify.error("An error occured while trying to save, please try again later");
						});
					}
				} else {
					_this.notify.error("Can't save because you are offline");
				}
			},
			resendVerification: function resendVerification() {
				var _this = this;

				this.get('firebaseApp').auth().onAuthStateChanged(function (user) {
					user.sendEmailVerification();
					_this.notify.success('Verification email send, please check your spam folder!');
				});
			},
			createDevice: function createDevice(value) {
				var _this2 = this;

				var user = this.get('session.currentUser');
				var device = this.store.createRecord('device', {
					name: value,
					user: user
				});

				device.save().catch(function (error) {
					this.notify.error("An error occured while trying to remove the brew device, please try again later");
					console.log(error);
				}.bind(this)).then(function (user) {
					_this2.notify.success('Brew device saved!');
				});
			},
			removeDevice: function removeDevice(value) {
				var currentUserId = this.get('session.currentUser.id');

				this.store.query('device', { orderBy: 'user', equalTo: currentUserId }).then(function (devices) {
					devices.forEach(function (device) {
						var _this3 = this;

						if (device.id === value) {
							var route = this;
							device.destroyRecord().catch(function (error) {
								this.notify.error("An error occured while trying to remove the brew device, please try again later");
								console.log(error);
							}.bind(this)).then(function () {
								_this3.notify.success('Brew device removed');
							});
						}
					}.bind(this));
				}.bind(this));
			},
			createGrinder: function createGrinder(value) {
				var _this4 = this;

				var user = this.get('session.currentUser');
				var grinder = this.store.createRecord('grinder', {
					name: value,
					user: user
				});

				grinder.save().catch(function (error) {
					this.notify.error("An error occured while trying to remove the grinder, please try again later");
					console.log(error);
				}.bind(this)).then(function (user) {
					_this4.notify.success('Grinder saved!');
				});
			},
			removeGrinder: function removeGrinder(value) {
				var currentUserId = this.get('session.currentUser.id');

				this.store.query('grinder', { orderBy: 'user', equalTo: currentUserId }).then(function (grinders) {
					grinders.forEach(function (grinder) {
						var _this5 = this;

						if (grinder.id === value) {
							var route = this;
							grinder.destroyRecord().catch(function (error) {
								this.notify.error("An error occured while trying to remove the grinder, please try again later");
								console.log(error);
							}.bind(this)).then(function () {
								_this5.notify.success('Grinder removed');
							});
						}
					}.bind(this));
				}.bind(this));
			},
			logout: function logout() {
				this.get('logout')(this);
			}
		}

	});
});