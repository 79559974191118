define('coffee-journal/helpers/eq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var eq = function eq(params) {
    return params[0] === params[1];
  };
  exports.default = Ember.Helper.helper(eq);
});