define('coffee-journal/routes/brews/brew', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		connectionStatus: Ember.inject.service(),

		model: function model(params) {
			return this.store.findRecord('brew', params.id);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);

			var userId = this.session.get('currentUser.id'),
			    grinders = this.store.query('grinder', { orderBy: 'user', equalTo: userId }),
			    devices = this.store.query('device', { orderBy: 'user', equalTo: userId });

			controller.set('grinders', grinders);
			controller.set('devices', devices);
		},


		actions: {
			toggleNotesVisibility: function toggleNotesVisibility() {
				debugger;
				document.querySelector('.form__column--notes').classList.toggle('is-visible');
			},
			saveChanges: function saveChanges(currentBrew) {
				var _this = this;

				if (this.get('connectionStatus.online')) {
					currentBrew.save().then(function () {
						_this.notify.success('Successfully saved changes!');
					}).catch(function () {
						_this.notify.error("An error occured while trying to save, please try again later");
					});
				} else {
					this.notify.error("Can't save because you are offline");
				}
			},
			favoriteBrew: function favoriteBrew(currentBrew) {

				if (currentBrew.get('favorite')) {
					currentBrew.set('favorite', false);
					this.set('data-favorite', false);
				} else {
					currentBrew.set('favorite', true);
					this.set('data-favorite', true);
				}
			},
			deleteBrew: function deleteBrew(currentBrew) {
				var route = this;
				var store = currentBrew.get('store');

				if (this.get('session.currentUser.id') === currentBrew.get('uid')) {

					var confirmDialog = confirm('This will permanently delete your brew, continue?');

					if (confirmDialog === true) {
						store.findRecord('brew', currentBrew.id, { backgroundReload: false }).then(function (brew) {
							brew.destroyRecord();
							route.notify.success('Brew successfully deleted');
							route.transitionTo('brews.index');
						});
					}
				} else {
					route.notify.error('You are not allowed to delete this brew');
				}
			},
			toggleBrewNotes: function toggleBrewNotes() {
				document.querySelector('.notes').classList.toggle('is-visible');
			},
			backToPreviousRoute: function backToPreviousRoute() {
				this.transitionTo('brews');
			}
		}

	});
});