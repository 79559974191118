define('coffee-journal/utils/intl/missing-message', ['exports', 'ember-intl/utils/links'], function (exports, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = missingMessage;
  var warn = Ember.warn;
  function missingMessage(key, locales) {
    if (!locales) {
      warn('[ember-intl] no locale has been set. Documentation: ' + _links.default.unsetLocale, false, {
        id: 'ember-intl-no-locale-set'
      });
    } else {
      warn('[ember-intl] translation: \'' + key + '\' on locale: \'' + locales.join(', ') + '\' was not found.', false, {
        id: 'ember-intl-missing-translation'
      });
    }

    return 'Missing translation: ' + key;
  }
});