define('coffee-journal/components/brew-list', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		sortedBrews: Ember.computed.sort('brews', 'sortDefinition'),
		currentSort: 'date',
		currentFilter: null,
		gridReversed: false,
		onlyFavorited: false,
		showChildren: true,
		filterChildren: true,
		viewStyle: 'list',
		brewList: null,
		searchVal: '',
		sortDefinition: Ember.computed('currentSort', function () {
			return ['brew_date:desc'];
		}),

		watchFavoriteToggle: function () {
			this.actions.filterFavorite(this.onlyFavorited, this);
		}.observes('filterFavorite'),

		watchChildrenToggle: function () {
			this.actions.filterChildren(this.showChildren, this);
		}.observes('filterChildren'),

		watchSortReverse: function () {
			this.actions.gridReverse(this);
		}.observes('gridReversed'),

		actions: {
			searchFilter: function searchFilter(param) {
				this.set('searchVal', param.currentTarget.value);
				var brewList = this.get('brewList'),
				    searchVal = param.currentTarget.value;

				var searchValReg = new RegExp(searchVal, 'gi');

				brewList.arrange({
					filter: function filter() {
						return searchValReg ? $(this).find('.brew__name').text().match(searchValReg) : true;
					}
				});
			},
			changeSort: function changeSort(sortValue) {
				var brewList = this.get('brewList');
				this.set('currentSort', sortValue);

				if (this.currentSort === 'date' || this.currentSort === 'rating') {
					this.set('gridReversed', false);
				} else {
					this.set('gridReversed', true);
				}

				brewList.arrange({
					sortBy: this.currentSort,
					sortAscending: this.gridReversed
				});
			},
			filterFavorite: function filterFavorite(filterStatus, element) {
				var brewList = element.get('brewList');

				if (filterStatus) {
					element.set('onlyFavorited', false);
					brewList.arrange({
						filter: '*'
					});
				} else {
					element.set('onlyFavorited', true);
					brewList.arrange({
						filter: '[data-favorite]'
					});
				}
			},
			filterChildren: function filterChildren(filterStatus, element) {
				var brewList = element.get('brewList');

				if (filterStatus) {
					element.set('showChildren', false);
					brewList.arrange({
						filter: '*:not([data-child])'
					});
				} else {
					element.set('showChildren', true);
					brewList.arrange({
						filter: '*'
					});
				}
			},
			gridReverse: function gridReverse(element) {

				if (element.gridReversed) {
					element.set('gridReversed', true);
				} else {
					element.set('gridReversed', false);
				}

				var brewList = element.get('brewList');

				brewList.arrange({
					sortBy: element.currentSort,
					sortAscending: element.gridReversed
				});
			},
			changeView: function changeView(viewValue) {
				this.set('viewStyle', viewValue);
				this.element.querySelector('.js_brews').classList.remove('brews--list', 'brews--cards');
				this.element.querySelector('.js_brews').classList.add('brews--list', 'brews--' + viewValue);

				var brewList = this.get('brewList');
				brewList.arrange();
			},
			toggleSideNav: function toggleSideNav() {
				this.element.querySelector('.sidenav').classList.toggle('is-hidden');
			}
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			// debugger

			var brewList = new Isotope('.js_brews', {
				itemSelector: '.brew',
				layoutMode: 'fitRows',
				stagger: 30,
				transitionDuration: 500,
				// filter: '*:not([data-child])', // Only hide children from list when in brew index, NOT in brew detail
				filter: function () {
					// debugger
					var searchVal = this.get('searchVal');
					return searchVal ? $(this).text().match(searchVal) : true;
				}.bind(this),
				fitRows: {
					gutter: 15
				},
				getSortData: {
					name: '.brew__name',
					origin: '.brew__origin',
					date: '[data-date]',
					rating: '[data-rating] parseInt'
				}
			});

			this.set('brewList', brewList);

			var list = this.element.querySelectorAll('.js_brews, .brew-filters');
			for (var i = 0; i < list.length; ++i) {
				list[i].classList.add('fade-in');
			}

			this.element.querySelector('.js_brews').classList.add('show-brews');
			brewList.revealItemElements(this.element.querySelectorAll('.js_brews .brew'));
		},
		didRender: function didRender() {
			var brewList = this.get('brewList');
			brewList.updateSortData();
		},
		willDestroyElement: function willDestroyElement() {
			var list = this.element.querySelectorAll('.js_brews, .brew-filters');
			for (var i = 0; i < list.length; ++i) {
				list[i].classList.add('fade-out');
			}

			var brewList = this.get('brewList');
			brewList.destroy();
		}
	});
});