define("coffee-journal/transitions", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		// Add your transitions here, like:
		// this.transition(
		// 	this.fromRoute('dashboard'),
		// 	this.toRoute('brews.index'),
		// 	this.use('toLeft'),
		// 	this.reverse('toRight')
		// );
	};
});