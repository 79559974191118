define('coffee-journal/controllers/brews/brew', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		actions: {

			handleTrixEvent: function handleTrixEvent(event) {
				var document = event.target.editor.getDocument();
				var raw = Trix.serializeToContentType(document, 'text/html');
				this.model.set('notes', raw);
			},

			toggleNotes: function toggleNotes() {
				this.toggleProperty('visibleNotes');
			},
			updateRating: function updateRating(rating) {
				this.model.set('rating', rating);
			}
		}

	});
});