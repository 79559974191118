define('coffee-journal/components/brew-timer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		moment: Ember.inject.service(),
		classNames: ['brew-timer'],
		state: 'reset',
		// TODO: Remove this ugly and potentially unsafe way to transition the route
		routing: Ember.inject.service('-routing'),

		timeStarted: null,
		timeElapsed: null,
		timeStopped: null,
		timeFormatted: '00:00',

		isPaused: false,
		isRunning: false,

		calculateTimeElapsed: function calculateTimeElapsed() {
			var timeCurrent = moment();

			if (this.get('isRunning')) {

				this.set('timeElapsed', timeCurrent.subtract(this.timeStarted.valueOf()));
				this.set('timeFormatted', moment(this.get('timeElapsed')).format('mm:ss'));

				if (this.get('routing').router.currentPath == 'brews.new') {
					document.querySelector('.brew__time').value = moment(this.get('timeElapsed')).format('mm:ss');
				}

				Ember.run.debounce(this, this.calculateTimeElapsed, 100);
			}
		},


		actions: {
			run: function run() {
				this.set('isPaused', false);
				this.toggleProperty('isRunning');
				this.timeStarted = this.get('timeStopped') ? moment().subtract(this.get('timeStopped').valueOf()) : moment();
				this.calculateTimeElapsed();
				this.get('routing').transitionTo('brews.new');
			},
			stop: function stop() {
				this.set('isPaused', true);
				this.toggleProperty('isRunning');
				this.set('timeStopped', this.get('timeElapsed'));
			},
			reset: function reset() {
				this.set('isPaused', false);
				this.set('isRunning', false);
				this.set('timeStopped', null);
				this.set('timeElapsed', null);
				this.set('timeFormatted', '00:00');
			}
		}
	});
});