define('coffee-journal/routes/dashboard', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {
			return this.store.query('brew', { limitToLast: 20 });
			// return this.modelFor('application');
		},
		setupController: function setupController(controller, model) {
			controller.set('dashboardBrews', model);
		}
	});
});