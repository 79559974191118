define('coffee-journal/components/brew-chart', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		classNames: 'chart-container',
		readOnly: true,

		didRender: function didRender() {
			this._super.apply(this, arguments);
			this.actions.createChart(this);
		},
		willRender: function willRender() {
			this._super.apply(this, arguments);

			if (this.new_chart) {
				this.new_chart.destroyChart();
			}
		},


		actions: {
			createChart: function createChart(_this) {
				var brew = _this.get('brew');

				_this.new_chart = new mRadarChart('#' + brew.id, {
					labels: ['Bitter', 'Sweet', 'Sour', 'Floral', 'Spicy', 'Salty', 'Berry', 'Citrus', 'Stone', 'Chocolate', 'Caramel', 'Smoky'],
					data: [brew.get('taste_bitter'), brew.get('taste_sweet'), brew.get('taste_sour'), brew.get('taste_floral'), brew.get('taste_spicy'), brew.get('taste_salty'), brew.get('taste_berry'), brew.get('taste_citrus'), brew.get('taste_stone'), brew.get('taste_chocolate'), brew.get('taste_caramel'), brew.get('taste_smoky')],
					lineStep: 1,
					step: 1,
					pointSize: 7,
					innerPadding: 30,
					// outerPadding: 20,
					// radius: 200,
					maxValue: 5,
					readOnly: _this.get('readOnly')
				});

				if (!_this.readOnly) {
					_this.new_chart.svgElement.addEventListener('change', function () {
						var new_data = _this.new_chart.getData();
						var taste_array = ['taste_bitter', 'taste_sweet', 'taste_sour', 'taste_floral', 'taste_spicy', 'taste_salty', 'taste_berry', 'taste_citrus', 'taste_stone', 'taste_chocolate', 'taste_caramel', 'taste_smoky'];

						for (var i = 0; i < taste_array.length; i++) {
							brew.set(taste_array[i], new_data[i]);
						}
					}.bind(_this));
				}

				_this.element.classList.add('scale-up');
			}
		}
	});
});