define('coffee-journal/components/journey-start', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: 'login',

		actions: {
			loginAction: function loginAction() {
				this.get('loginAction')(this);
			},
			signupAction: function signupAction() {
				this.get('signupAction')(this);
			}
		},

		didInsertElement: function didInsertElement() {
			this.element.querySelector('.login__box').classList.add('fade-in');
		}
	});
});