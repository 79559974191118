define('coffee-journal/components/brew-summary', ['exports', 'ember-gestures/mixins/recognizers'], function (exports, _recognizers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_recognizers.default, {
		recognizers: 'pan',
		classNames: ['brew'],
		attributeBindings: ['data-rating', 'data-favorite', 'data-date', 'data-child', 'data-name'],
		'data-rating': null,
		'data-favorite': false,
		'data-date': false,
		'data-child': false,
		'data-name': '',
		matchedSession: false,

		pan: function pan(e) {
			event.preventDefault();

			if (window.innerWidth <= 1280) {

				var dragDistance = event.gesture.deltaX,
				    currentTarget = $(event.target),
				    dragged;

				if (currentTarget.hasClass('brew')) {
					dragged = currentTarget;
				} else {
					dragged = currentTarget.parents('.brew');
				}

				var elementOffset = dragged.offset().left,
				    calculatedOffset = elementOffset + dragDistance;

				if (calculatedOffset < 0 && calculatedOffset >= -200) {
					dragged.css('left', '' + calculatedOffset + 'px');

					// dragged.find('.distance').text(dragDistance);

					if (calculatedOffset > -50) {
						dragged.css('left', '0');
					}

					if (calculatedOffset < -50 && calculatedOffset > -150) {
						dragged.css('left', '-100px');
					}

					if (calculatedOffset < -150 && calculatedOffset > -200) {
						dragged.css('left', '-200px');
					}

					if (calculatedOffset < -100 && event.gesture.velocity < -2.5) {
						dragged.css('left', 'calc(-100% - 200px)');

						var confirmDialog = confirm('This will permanently delete your brew, continue?');
						var store = this.object.get('store');

						if (confirmDialog === true) {

							store.findRecord('brew', this.object.id, { backgroundReload: false }).then(function (brew) {
								var brewCard = event.target.closest('.brew'),
								    iso = new Isotope('.js_brews');

								iso.remove(brewCard);
								iso.layout();
								brew.destroyRecord();
							});
						} else {
							dragged.css('left', '-200px');
						}
					}
				}
			}
		},


		actions: {
			favoriteBrew: function favoriteBrew(currentBrew) {
				var store = currentBrew.get('store'),
				    component = this;

				if (component.get('session.currentUser.id') === currentBrew.get('uid')) {
					store.findRecord('brew', currentBrew.id, { backgroundReload: false }).then(function (brew) {
						if (brew.get('favorite')) {
							brew.set('favorite', false);
							component.set('data-favorite', false);
						} else {
							brew.set('favorite', true);
							component.set('data-favorite', true);
						}
						brew.save();
					});
				}
			},
			deleteBrew: function deleteBrew(currentBrew) {
				var store = currentBrew.get('store'),
				    confirmDialog = confirm('This will permanently delete your brew, continue?');

				if (this.get('session.currentUser.id') === currentBrew.get('uid')) {

					if (confirmDialog === true) {
						store.findRecord('brew', currentBrew.id, { backgroundReload: false }).then(function (brew) {
							var brewCard = event.target.closest('.brew'),
							    iso = new Isotope('.js_brews');

							iso.remove(brewCard);
							iso.layout();
							brew.destroyRecord();
						});
					}
				}
			}
		},

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			this.set('data-rating', this.get('object').get('rating'));
			this.set('data-favorite', this.get('object').get('favorite'));
			this.set('data-date', this.get('object').get('brew_date'));
			this.set('data-name', this.get('object').get('name'));

			if (this.get('object.parent.content')) {
				this.set('data-child', true);
			}
		}
	});
});