define('coffee-journal/routes/brews/new', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		connectionStatus: Ember.inject.service(),
		parentBrewId: null,

		beforeModel: function beforeModel(transition) {
			// If duplicate query param is there, use it and link it to parentBrewId
			this.set('parentBrewId', transition.queryParams.duplicate);
		},
		model: function model() {
			// Peek at current user and parent (if available) to use them for the new brew
			var user = this.store.peekRecord('user', this.get('session.currentUser.id')),
			    parentBrew = this.store.peekRecord('brew', this.get('parentBrewId'));

			// Create new brew
			if (this.get('parentBrewId')) {

				// Link parent if queryParams id is given and pre-enter all values from the parent
				return Ember.RSVP.hash({
					brew: this.store.createRecord('brew', {
						user: user,
						parent: parentBrew,
						name: parentBrew.get('name'),
						origin: parentBrew.get('origin'),
						roaster: parentBrew.get('roaster'),
						producer: parentBrew.get('producer'),
						varietal: parentBrew.get('varietal'),
						wash: parentBrew.get('wash'),
						roast: parentBrew.get('roast'),
						roast_date: parentBrew.get('roast_date'),
						package_date: parentBrew.get('package_date'),
						brew_date: moment().format("YYYY-MM-DD"),
						beverage: parentBrew.get('beverage'),
						brew_device: parentBrew.get('brew_device'),
						grinder: parentBrew.get('grinder'),
						url: parentBrew.get('url'),
						wash_blend: parentBrew.get('wash_blend'),
						roast_blend: parentBrew.get('roast_blend'),
						origin_blend: parentBrew.get('origin_blend'),
						name_blend: parentBrew.get('name_blend'),
						roaster_blend: parentBrew.get('roaster_blend'),
						producer_blend: parentBrew.get('producer_blend')
					})
				});
			} else {

				// Just create brew and link to current user
				return Ember.RSVP.hash({
					brew: this.store.createRecord('brew', {
						user: user
					})
				});
			}
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);

			var userId = this.session.get('currentUser.id'),
			    grinders = this.store.query('grinder', { orderBy: 'user', equalTo: userId }),
			    devices = this.store.query('device', { orderBy: 'user', equalTo: userId });

			controller.set('grinders', grinders);
			controller.set('devices', devices);
		},


		actions: {
			toggleNotes: function toggleNotes() {
				document.querySelector('.notes').classList.toggle('is-visible');
			},
			updateCurrentBrew: function updateCurrentBrew(currentBrew) {
				var _this2 = this;

				var _this = this;
				currentBrew.set('uid', this.get('session.currentUser.id'));

				if (this.get('connectionStatus.online')) {
					currentBrew.save().then(function (currentBrew) {
						_this.notify.success("Successfully added your new brew");
						_this2.transitionTo('brews.brew', currentBrew.id);
					}).catch(function () {
						_this.notify.error("An error occured while trying to save, please try again later");
					});
				} else {
					this.notify.error("Can't save this brew because you are offline");
				}
			}
		}

	});
});