define('coffee-journal/controllers/user', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		applicationController: Ember.inject.controller('application'),
		currentSubscription: Ember.computed.alias('applicationController.currentSubscription'),
		firebaseApp: Ember.inject.service(),
		isNotVerified: true,
		reauthenticationIsRequired: false,
		localeOptions: [{
			id: 1,
			value: 'en-us',
			title: 'English/American'
		}, {
			id: 2,
			value: 'nl-nl',
			title: 'Dutch'
		}],

		actions: {
			reauthenticate: function reauthenticate() {
				// https://firebase.google.com/docs/reference/js/firebase.User.html#reauthenticateWithCredential
				var email = this.get('email'),
				    password = this.get('password');

				var cred = firebase.auth.EmailAuthProvider.credential(email, password);

				this.get('firebaseApp').auth().currentUser.reauthenticateWithCredential(cred).then(function () {
					this.set('reauthenticationIsRequired', false);
					// https://firebase.google.com/docs/reference/js/firebase.User.html#reload
					this.get('firebaseApp').auth().currentUser.reload();
					this.notify.success('Successfully saved changes!');
				}.bind(this)).catch(function (error) {
					console.log(error);
					this.notify.error(error.code);
				}.bind(this));
			},
			cancelAuthentication: function cancelAuthentication() {
				this.set('reauthenticationIsRequired', false);
			},
			logout: function logout() {
				this.store.unloadAll();
				this.get('session').close();
				this.transitionToRoute('/');
			} // logout

		}
	});
});