define('coffee-journal/initializers/cordova-device-ready', ['exports', 'coffee-journal/utils/is-on-cordova'], function (exports, _isOnCordova) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.deferReadiness();
    document.addEventListener('deviceready', function () {
      application.advanceReadiness();
    }, false);
    if (!(0, _isOnCordova.default)()) {
      document.dispatchEvent(new Event('deviceready'));
    }
  }

  exports.default = {
    name: 'cordova-device-ready',
    initialize: initialize
  };
});