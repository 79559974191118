define("coffee-journal/utils/is-on-cordova", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isOnCordova;
  function isOnCordova() {
    return !!window.cordova;
  }
});