define('coffee-journal/components/navigation-mobile', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'nav',
		classNames: ['navigation--mobile'],

		actions: {
			toggleTimer: function toggleTimer(event) {
				event.element.querySelector('.navigation-item--timer').classList.toggle('is-active');
				document.querySelector('.brew-timer').classList.toggle('is-visible');
			}
		}

	});
});