define('coffee-journal/routes/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		intl: Ember.inject.service(),
		firebaseApp: Ember.inject.service(),
		connectionStatus: Ember.inject.service(),
		subscriptions: ['Free', 'Standard', 'Deluxe'],

		init: function init() {
			this.get('connectionStatus').setup(this);
		},


		status: Ember.computed('connectionStatus.online', function () {
			return this.get('connectionStatus.online') ? 'Online' : 'Offline';
		}),

		beforeModel: function beforeModel() {
			this.get('intl').setLocale('en-us');
		},
		model: function model() {

			if (this.session.get('isAuthenticated')) {
				var currentUserId = this.get('session.currentUser.id');
				this.get('intl').setLocale(this.get('session.currentUser.locale'));

				this.get('firebaseApp').auth().onAuthStateChanged(function (user) {

					if (user) {

						if (!user.emailVerified) {
							this.notify.warning("Your email address is not verified, you won't be able to save brews");
						}
					}
				}.bind(this));

				return Ember.RSVP.hash({
					user: this.get('session.currentUser'),
					userBrews: this.store.query('brew', { orderBy: 'uid', equalTo: currentUserId })
				});
			}
		},


		actions: {
			accessDenied: function accessDenied() {
				this.transitionTo('/');
			},
			online: function online() {
				this.notify.success("Welcome back online! 🎉");
			},
			offline: function offline() {
				this.notify.warning("Houston, we have a problem. You are offline 😨");
			}
		}

	});
});