define('coffee-journal/components/g-recaptcha', ['exports', 'ember-g-recaptcha/components/g-recaptcha'], function (exports, _gRecaptcha) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gRecaptcha.default;
    }
  });
});