define('coffee-journal/components/navigation-primary', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'nav',
		classNames: ['navigation', 'navigation--primary'],

		actions: {
			logout: function logout() {
				this.get('logout')(this);
			}
		}
	});
});