define('coffee-journal/components/header-primary', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'header',
		classNames: ['header', 'header--primary'],
		attributeBindings: ['data-height', 'data-fontsize'],
		'data-height': 200,
		'data-fontsize': 30,

		scrollHandler: function scrollHandler() {
			var sizes = this.get('sizes');

			var scrollTop = window.scrollY,
			    sizePercentage = scrollTop / sizes.initial.headerHeight;
			// sizePercentageFix = (sizePercentage < 1 ? sizePercentage : 1);
			// newHeaderHeight = sizes.ranges.headerHeightRange - (sizes.ranges.headerHeightRange * sizePercentageFix),
			// newFontSize = sizes.ranges.fontSizeRange - (sizes.ranges.fontSizeRange * sizePercentageFix);

			var newHeaderHeight = sizes.initial.headerHeight - scrollTop;

			if (newHeaderHeight > 150) {
				this.element.style.height = newHeaderHeight + 'px';
			} else {
				this.element.style.height = '150px';
			}

			// this.element.style.height = sizes.final.headerHeight + newHeaderHeight + 'px';
			// this.pageTitleElement.style.fontSize = sizes.final.fontSize + newFontSize + 'px';
		},
		initHeaderSize: function initHeaderSize() {
			var headerStyle = window.getComputedStyle(this.element),
			    dataHeight = window.outerWidth < 768 ? 50 : this.get('data-height'),
			    dataFontSize = window.outerWidth < 768 ? 14 : this.get('data-fontsize'),
			    pageTitleElement = this.element.querySelector('.page-title'),
			    titleStyle = window.getComputedStyle(pageTitleElement);

			this.set('pageTitleElement', pageTitleElement);

			var sizes = {
				initial: {
					headerHeight: parseInt(headerStyle.getPropertyValue('height').slice(0, -2)),
					fontSize: parseInt(titleStyle.getPropertyValue('font-size').slice(0, -2))
				}
			};

			sizes.final = {
				headerHeight: dataHeight,
				fontSize: dataFontSize
			};

			sizes.ranges = {
				headerHeightRange: sizes.initial.headerHeight - dataHeight,
				fontSizeRange: sizes.initial.fontSize - dataFontSize
			};

			this.set('sizes', sizes);
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var elements = this.element.querySelectorAll('.page-title, .header-actions');
			for (var i = 0; i < elements.length; i++) {
				elements[i].classList.add('fade-in');
			}

			//TODO: Globale functie hier van maken!!

			function throttle(type, name, obj) {
				var running = false;

				obj = obj || window;

				var func = function func() {
					if (running) {
						return;
					}

					running = true;

					requestAnimationFrame(function () {
						obj.dispatchEvent(new CustomEvent(name));
						running = false;
					});
				};

				obj.addEventListener(type, func);
			}

			throttle('scroll', 'scroll.throttled');
			this.initHeaderSize(this);

			this.boundScrollHandler = this.scrollHandler.bind(this);
			window.addEventListener('scroll.throttled', this.boundScrollHandler);
		},
		willDestroyElement: function willDestroyElement() {
			var elements = this.element.querySelectorAll('.page-title, .brew-filters');
			for (var i = 0; i < elements.length; i++) {
				elements[i].classList.add('fade-out');
			}
			window.removeEventListener('scroll.throttled', this.boundScrollHandler);
		}
	});
});