define('coffee-journal/routes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		model: function model() {

			if (this.session.get('isAuthenticated')) {
				// this.transitionTo('dashboard');
				this.transitionTo('brews');
			}
		}
	});
});