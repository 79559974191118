define('coffee-journal/models/device', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		name: _emberData.default.attr('string'),
		user: _emberData.default.belongsTo('user'),
		brews: _emberData.default.hasMany('brew')
	});
});