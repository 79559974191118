define('coffee-journal/components/ember-trix', ['exports', 'ember-trix/components/ember-trix'], function (exports, _emberTrix) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberTrix.default;
    }
  });
});